import * as React from "react";
import SEO from "../components/seo";
import { Link } from "gatsby";

const NotFoundPage = () => (
  <div className="container404">
    <SEO title="404: Not found" />
    <h1>404: Not Found</h1>
    <h2>You just hit a route that doesn&#39;t exist...</h2>
    <Link to="/">
      <h3>Come back to home</h3>
    </Link>
  </div>
)

export default NotFoundPage;
